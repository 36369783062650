<template>
  <div>
    <el-input
      :id="propDataMap.id+'-input'"
      type="text"
      v-model="mData.searchMap"
      placeholder="Tìm kiếm địa điểm để lấy vị trí"
      :disabled="propDisabled"
    ></el-input>
    <div :id="propDataMap.id" class="googleMap-input" style="min-height:400px;height: 400px"></div>
  </div>
</template>

<script>
import LanguageService from "../service/LanguageService";
import MethodService from "../service/MethodService";

export default {
  props: {
    propDataMap: Object,
    propDisabled: Boolean
  },
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        searchMap: ""
      }
    };
  },
  methods: {
    fn_initMap() {
      var _this = this;

      // khai báo map
      var map = new google.maps.Map(
        document.getElementById(_this.propDataMap.id),
        {
          zoom: _this.propDataMap.lat && _this.propDataMap.lat ? 15 : 5.2,
          center: {
            lat: _this.propDataMap.lat ? _this.propDataMap.lat : 16.35451,
            lng: _this.propDataMap.lng ? _this.propDataMap.lng : 108.00759
          },
          mapTypeId: "roadmap",
          scrollwheel: false
        }
      );

      //nếu có tọa độ, gán tọa độ

      if (_this.propDataMap.lat && _this.propDataMap.lng) {
        var markerInit = new google.maps.Marker({
          position: {
            lat: _this.propDataMap.lat,
            lng: _this.propDataMap.lng
          },
          draggable: _this.propDisabled ? false : true
        });
        markerInit.setMap(map);

        markerInit.addListener("dragend", fn_dragMarkerInit);

        function fn_dragMarkerInit() {
          var position = markerInit.getPosition();
          let lat = position.lat();
          let lng = position.lng();
          _this.propDataMap.fn_addLatLng(lat, lng);
          fn_reloadLabel();
        }

        var infos = [];

        function fn_reloadLabel() {
          infos.forEach(function(info) {
            info.close();
          });
          infos = [];
          let content = "";
          let position = markerInit.getPosition();
          let lat = position.lat();
          let lng = position.lng();
          if (_this.propDataMap.updateLastTime) {
            let time = MethodService.formatDate(
              _this.propDataMap.updateLastTime,
              "datetime"
            );
            let aboutTime = MethodService.getAboutTime(
              _this.propDataMap.updateLastTime
            );
            content += `<div>Cập nhật lần cuối: ${aboutTime}</div><div>Thời điểm: ${time}</div>`;
          } else {
            content += `<div>Kinh độ: ${lng}</div><div>Vĩ độ: ${lat}</div>`;
          }

          let infowindow = new google.maps.InfoWindow({
            content: content
          });
          infos.push(infowindow);

          infowindow.open(map, markerInit);
        }

        markerInit.addListener("click", function() {
          fn_reloadLabel();
        });
      }

      // Create the search box and link it to the UI element.
      var input = document.getElementById(_this.propDataMap.id + "-input");
      var searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", function() {
        searchBox.setBounds(map.getBounds());
      });

      var markers = [];
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", function() {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        markers.forEach(function(marker) {
          marker.setMap(null);
        });
        markers = [];

        if (markerInit) {
          markerInit.setMap(null);
        }

        // For each place, get the icon, name and location.
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }

          var lat = place.geometry.location.lat();
          var lng = place.geometry.location.lng();
          //trả ra vị trí lat long
          _this.propDataMap.fn_addLatLng(lat, lng);

          // Create a marker for each place.
          var marker = new google.maps.Marker({
            map: map,
            title: place.name,
            position: place.geometry.location,
            draggable: _this.propDisabled ? false : true
          });

          markers.push(marker);

          //kéo marker
          marker.addListener("dragend", fn_dragMarker);

          function fn_dragMarker() {
            var position = marker.getPosition();
            let lat = position.lat();
            let lng = position.lng();
            _this.propDataMap.fn_addLatLng(lat, lng);
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    }
  },
  created: function() {},
  mounted: function() {
    this.fn_initMap();
  }
};
</script>
<style scoped>
</style>