<template>
  <div>
    <tui-editor
      v-model="propDataEditor[propDataEditorKey]"
      :options="mData.markdownOption.options"
      :height="height ? height : '400px'"
      :previewStyle="mData.markdownOption.previewStyle"
      ref="tuiEditor"
      class="tui-editor-style"
      :disabled="propDataEditorDisabled"
    ></tui-editor>
    <!-- dialog mardown -->
    <el-dialog
      :close-on-click-modal="false"
      title="Chèn ảnh"
      :visible.sync="mData.markdownModel.dialogImage"
    >
      <el-form
        :model="mData.markdownModel.dataFormImage"
        :rules="mData.markdownModel.validFormImage"
        ref="myFormMarkdownImage"
        label-width="100%"
        label-position="top"
      >
        <b-col md="12" class="mt-2">
          <b-tabs>
            <b-tab title="Tải lên" active @click="fn_markdownResetModal('insert')">
              <b-col md="12">
                <el-form-item label="Tiêu đề" prop="title">
                  <el-input v-model="mData.markdownModel.dataFormImage.title" clearable></el-input>
                </el-form-item>
              </b-col>
              <b-col md="12">
                <el-form-item label prop="file">
                  <input
                    type="file"
                    name="inputFile"
                    :id="'inputFile'+ propDataEditorKey"
                    accept=".jpg, .png, .jpeg"
                  />
                </el-form-item>
              </b-col>
            </b-tab>
            <b-tab title="Đường dẫn" @click="fn_markdownResetModal('link')">
              <b-col md="12">
                <el-form-item label="Tiêu đề" prop="title">
                  <el-input v-model="mData.markdownModel.dataFormImage.title" clearable></el-input>
                </el-form-item>
              </b-col>
              <b-col md="12">
                <el-form-item label="Đường dẫn" prop="link">
                  <el-input v-model="mData.markdownModel.dataFormImage.link" clearable></el-input>
                </el-form-item>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="fn_markdownInsertImage()">{{aLang.c_update}}</el-button>
        <el-button
          @click="mData.markdownModel.dialogImage = false"
          type="info"
          plain
        >{{aLang.c_close}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LanguageService from "../service/LanguageService";
import MethodService from "../service/MethodService";
import ExtensionService from "../service/ExtensionService";
import { Editor } from "@toast-ui/vue-editor";

export default {
  props: {
    propDataEditor: Object,
    propDataEditorKey: String,
    propDataEditorDisabled: Boolean,
    height: String
  },
  components: {
    TuiEditor: Editor
  },
  data() {
    return {
      aLang: LanguageService.lang,
      aElement: MethodService.element,
      mData: {
        markdownOption: MethodService.copyObject(MethodService.markdownOption),
        markdownModel: MethodService.copyObject(MethodService.markdownModel)
      }
    };
  },
  methods: {
    fn_init() {
      let editor = this.$refs.tuiEditor.invoke("getCurrentModeEditor");
      let ui = this.$refs.tuiEditor.invoke("getUI");
      let toolbar = ui.getToolbar();
      editor.eventManager.addEventType("customUploadImage");

      editor.eventManager.listen("customUploadImage", () => {
        this.mData.markdownModel.dialogImage = true;
      });

      //thêm button image '<button type="button" class="custom-button"><i class="icon-link"></i></button>'
      toolbar.addButton(
        {
          name: "Upload Image",
          tooltip: "Upload Image",
          event: "customUploadImage",
          $el: $(
            '<button type="button" class="tui-image tui-toolbar-icons"></button>'
          )
        },
        13
      );
    },
    fn_markdownResetModal(typeTab) {
      this.mData.markdownModel.dataFormImage.title = null;
      this.mData.markdownModel.dataFormImage.link = null;
      this.mData.markdownModel.dialogImageTab = typeTab;
      $("#inputFile").val(null);
    },
    fn_markdownInsertImage() {
      this.$refs["myFormMarkdownImage"].validate(async valid => {
        if (valid) {
          if (this.mData.markdownModel.dialogImageTab == "insert") {
            var file = $("#inputFile" + this.propDataEditorKey)[0].files[0];
            if (file) {
              let rawDataFile = {
                raw: file, // required
                name: file.name // optional
              };
              let res = await ExtensionService.upload(rawDataFile, "markdown");
              let editor = this.$refs.tuiEditor.invoke("getCodeMirror");
              editor.replaceSelection(
                `![${
                  this.mData.markdownModel.dataFormImage.title
                    ? this.mData.markdownModel.dataFormImage.title
                    : "Tiêu đề"
                }](${ExtensionService.urlImg + res.result})`
              );
              this.fn_markdownResetModal("insert");
              this.mData.markdownModel = MethodService.copyObject(
                MethodService.markdownModel
              );
            } else {
              let editor = this.$refs.tuiEditor.invoke("getCodeMirror");
              editor.replaceSelection(
                `![${
                  this.mData.markdownModel.dataFormImage.title
                    ? this.mData.markdownModel.dataFormImage.title
                    : "Tiêu đề"
                }](Đường dẫn)`
              );
              this.fn_markdownResetModal("insert");
              this.mData.markdownModel = MethodService.copyObject(
                MethodService.markdownModel
              );
            }
          }
          if (this.mData.markdownModel.dialogImageTab == "link") {
            let editor = this.$refs.tuiEditor.invoke("getCodeMirror");
            editor.replaceSelection(
              `![${
                this.mData.markdownModel.dataFormImage.title
                  ? this.mData.markdownModel.dataFormImage.title
                  : "Tiêu đề"
              }](${
                this.mData.markdownModel.dataFormImage.link
                  ? this.mData.markdownModel.dataFormImage.link
                  : "Đường dẫn"
              })`
            );
            this.fn_markdownResetModal("insert");
            this.mData.markdownModel = MethodService.copyObject(
              MethodService.markdownModel
            );
          }
        } else {
          return false;
        }
      });
    }
  },
  created: function() {},
  mounted: function() {
   this.fn_init();
  }
};
</script>
<style scoped>
</style>
